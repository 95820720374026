
import NotificationTag, { NOTIFICATIONTYPES } from "../NotificationTag.vue";
import IncidentIcon from "../IncidentIcon.vue";
import IncidentPopover from "../IncidentPopover.vue";
import LocationPopover from "../LocationPopover.vue";
import "tippy.js/dist/tippy.css";
import {
  computed,
  defineComponent,
  ref,
  ComponentPublicInstance,
  PropType,
} from "vue";
import { IApplianceCadData } from "@/models/applianceVm";
import { formatApplianceDate } from "@/helpers/dateHelpers";
import { generateMapLink, generateReportLink } from "@/helpers/actionLinks";

export default defineComponent({
  name: "SmartStatusAppliancesTableAppliance",
  components: {
    NotificationTag,
    IncidentIcon,
    IncidentPopover,
    LocationPopover,
  },
  props: {
    callSignCode: String,
    kCodeIdentifier: String,
    kCodeStatus: { type: String, default: "" },
    updatedTimestamp: Date,
    eventTypeDescription: String,
    cadNumber: String,
    eventTypeCode: String,
    // Don't like this approach but need to stop focusing on this too much for now
    // TODO use Bootstrap to add table-stripe rather than doing it manually
    // TODO maybe change the html to be an actual table??? Might make mobile harder...
    oddKey: Boolean,
    cadEvent: { type: Object as PropType<IApplianceCadData>, required: false },
    specialLocation: { type: String, required: false },
    xLocation: Number,
    yLocation: Number,
  },

  setup(props) {
    // #region data

    const popupRef = ref<ComponentPublicInstance | null>(null);
    const incidentPopoverId = ref(`${props.callSignCode}-incidentIcon`);
    const locationPopoverId = ref(`${props.callSignCode}-locationIcon`);

    const reportLink = props.cadNumber
      ? generateReportLink(props.cadNumber)
      : "";

    let mapLink = "";
    if (props.xLocation && props.yLocation)
      mapLink = generateMapLink(props.xLocation, props.yLocation);

    // TODO just use values.toLower for the classes (change class names)?
    const topClass = computed((): NOTIFICATIONTYPES => {
      if (props.kCodeStatus.toLowerCase().includes("unavailable")) {
        return NOTIFICATIONTYPES.UNAVAILABLE;
      } else if (props.kCodeStatus.toLowerCase().includes("enroute")) {
        return NOTIFICATIONTYPES.ENROUTE;
      } else if (props.kCodeStatus.toLowerCase().includes("scene")) {
        return NOTIFICATIONTYPES.ONSCENE;
      } else if (props.kCodeStatus.toLowerCase().includes("road")) {
        return NOTIFICATIONTYPES.ONROAD;
      } else if (props.kCodeStatus.toLowerCase().includes("station")) {
        return NOTIFICATIONTYPES.ONSTATION;
      } else {
        return NOTIFICATIONTYPES.GREY;
      }
    });

    const showLocationData = computed(() => {
      if (props.kCodeIdentifier === "K3" || props.kCodeIdentifier === "K9") {
        return true;
      } else {
        return false;
      }
    });

    const displayDate = computed((): string => {
      return formatApplianceDate(props.updatedTimestamp);
    });

    const showEventTypeDescription = computed((): boolean => {
      return !!props.eventTypeDescription && !!props.cadNumber;
    });
    // #endregion

    return {
      topClass,
      popupRef,
      showLocationData,
      displayDate,
      showEventTypeDescription,
      incidentPopoverId,
      locationPopoverId,
      reportLink,
      mapLink,
    };
  },
});
