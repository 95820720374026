import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import { defineStore } from "pinia";
import _ from "lodash";

export interface UnitArea {
  unitCode: string;
  parentUnitCode: string | null;
  unitName: string;
  unitLevel: number;
  unitLevelName: string;
  additionalAttribute?: string;
  additionalAttributeMeaning?: string;
}

export const useRegionStore = defineStore("regions", {
  state: () => ({
    units: [] as UnitArea[],
  }),
  getters: {
    getRegions(state): UnitArea[] {
      return _.orderBy(
        state.units.filter((u) => u.unitLevel == 1),
        (u) => u.unitCode
      );
    },
    getDistricts(state): UnitArea[] {
      return _.orderBy(
        state.units.filter((u) => u.unitLevel == 2),
        (u) => u.unitCode
      );
    },
    getStations(state): UnitArea[] {
      return _.orderBy(
        state.units.filter((u) => u.unitLevel == 3),
        (u) => u.unitCode
      );
    },
  },
  actions: {
    async loadRegionsFromApi() {
      const client: IIncrClient = new IncrClient();
      const units = await client.getUnits();
      this.units = units;
    },
  },
});
