
import { defineComponent } from "vue";
import Tooltip from "./Tooltip.vue";

export default defineComponent({
  name: "LocationPopover",
  components: { Tooltip },
  props: {
    heading: String,
    location: String,
    popoverId: String,
    specialLocation: String,
  },
});
