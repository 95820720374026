import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "callsign" }
const _hoisted_2 = { class: "status" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "updated" }
const _hoisted_5 = { class: "icad-desc" }
const _hoisted_6 = {
  key: 1,
  class: "incident"
}
const _hoisted_7 = {
  key: 0,
  class: "seperator"
}
const _hoisted_8 = { class: "icad-num" }
const _hoisted_9 = {
  key: 1,
  class: "actions"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_NotificationTag = _resolveComponent("NotificationTag")!
  const _component_IncidentIcon = _resolveComponent("IncidentIcon")!
  const _component_IncidentPopover = _resolveComponent("IncidentPopover")!
  const _component_LocationPopover = _resolveComponent("LocationPopover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["appliances-table-appliance", _ctx.topClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["appliances-table-appliance__card", { grey: _ctx.oddKey }])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.callSignCode), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.kCodeStatus) + " ", 1),
          (_ctx.showLocationData)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "comment-alt",
                class: "location-icon",
                id: _ctx.locationPopoverId
              }, null, 8, ["id"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_NotificationTag, {
          class: "tag",
          value: _ctx.kCodeIdentifier,
          type: _ctx.topClass
        }, null, 8, ["value", "type"])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.displayDate), 1),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.showEventTypeDescription)
          ? (_openBlock(), _createBlock(_component_IncidentIcon, {
              key: 0,
              type: _ctx.eventTypeCode,
              class: "icon",
              id: _ctx.incidentPopoverId
            }, null, 8, ["type", "id"]))
          : _createCommentVNode("", true),
        (_ctx.showEventTypeDescription)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.eventTypeDescription), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showEventTypeDescription)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "|"))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.cadNumber), 1),
      (_ctx.cadNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("a", {
              class: "map-button",
              href: _ctx.mapLink
            }, "Map", 8, _hoisted_10),
            _createElementVNode("a", {
              class: "report-button",
              href: _ctx.reportLink,
              target: "_blank"
            }, "Report", 8, _hoisted_11)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_IncidentPopover, {
      callSignCode: _ctx.callSignCode,
      kCodeIdentifier: _ctx.kCodeIdentifier,
      cadEvent: _ctx.cadEvent,
      popoverId: _ctx.incidentPopoverId,
      id: "tooltip",
      class: "d-none",
      ref: "popupRef"
    }, null, 8, ["callSignCode", "kCodeIdentifier", "cadEvent", "popoverId"]),
    _createVNode(_component_LocationPopover, {
      heading: "Location",
      location: "@ Placeholder Station",
      targetId: "locationIcon",
      popoverId: _ctx.locationPopoverId,
      specialLocation: _ctx.specialLocation,
      id: "tooltip",
      class: "d-none",
      ref: "popupRef"
    }, null, 8, ["popoverId", "specialLocation"])
  ], 2))
}