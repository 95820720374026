import { defineStore } from "pinia";
import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import { useLoadingStateStore } from "./loadingState";
import {
  ICadEventDto,
  ICadEventVm,
  IEventTypeDto,
  toCadEventVm,
} from "@/models/icadEventVm";
import { ICadEventsListFilter } from "@/data/cadEventsFilter";
import { UnitArea } from "./regions";
import { useRegionStore } from "@/store/regions";
import _ from "lodash";
import { getUserAccountId } from "@/auth/getUserAccountId";

const genericLoadingFunction = async (filters: ICadEventsListFilter) => {
  const loadingStateStore = useLoadingStateStore();
  loadingStateStore.eventsLoading = true;
  const client: IIncrClient = new IncrClient();
  const accountId = getUserAccountId();
  // NOTE: includes state: "RE-OPENED" events
  const cadEvents: ICadEventVm[] = [];
  try {
    const events = await client.getCadEventsList(await accountId, filters);
    cadEvents.push(...events.map(toCadEventVm));
  } catch {
    console.error("Error loading Events from API");
  } finally {
    loadingStateStore.eventsLoading = false;
  }
  return cadEvents;
};

export const SORTBY = {
  ASC: "asc",
  DESC: "desc",
} as const;

export type SORTBY = typeof SORTBY[keyof typeof SORTBY];

const CADSORTFIELDS = {
  DEFAULT: "",
  CAD: "CAD Number",
  TYPE: "Type",
  START: "Start date/time",
  ADDRESS: "Address",
  RESULT: "Result",
} as const;

export type CADSORTFIELDS = typeof CADSORTFIELDS[keyof typeof CADSORTFIELDS];

export const CADSORTOPTIONS = {
  DEFAULT: {
    sortField: CADSORTFIELDS.DEFAULT,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
  CAD_ASC: {
    sortField: CADSORTFIELDS.CAD,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.CAD_DESC as CADSORTOPTIONS,
  },
  CAD_DESC: {
    sortField: CADSORTFIELDS.CAD,
    sortBy: SORTBY.DESC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
  TYPE_ASC: {
    sortField: CADSORTFIELDS.TYPE,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.TYPE_DESC as CADSORTOPTIONS,
  },
  TYPE_DESC: {
    sortField: CADSORTFIELDS.TYPE,
    sortBy: SORTBY.DESC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
  START_ASC: {
    sortField: CADSORTFIELDS.START,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.START_DESC as CADSORTOPTIONS,
  },
  START_DESC: {
    sortField: CADSORTFIELDS.START,
    sortBy: SORTBY.DESC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
  ADDRESS_ASC: {
    sortField: CADSORTFIELDS.ADDRESS,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.ADDRESS_DESC as CADSORTOPTIONS,
  },
  ADDRESS_DESC: {
    sortField: CADSORTFIELDS.ADDRESS,
    sortBy: SORTBY.DESC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
  RESULT_ASC: {
    sortField: CADSORTFIELDS.RESULT,
    sortBy: SORTBY.ASC,
    nextOption: () => CADSORTOPTIONS.RESULT_DESC as CADSORTOPTIONS,
  },
  RESULT_DESC: {
    sortField: CADSORTFIELDS.RESULT,
    sortBy: SORTBY.DESC,
    nextOption: () => CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
  },
};

export type CADSORTOPTIONS = typeof CADSORTOPTIONS[keyof typeof CADSORTOPTIONS];

export const useCadEventStore = defineStore("cadEvents", {
  state: () => ({
    events: [] as ICadEventVm[],
    eventTypes: [] as IEventTypeDto[],
    previousSelectedRegion: "",
    selectedSortOption: CADSORTOPTIONS.DEFAULT as CADSORTOPTIONS,
    sortedEvents: [] as ICadEventDto[],

    filters: {
      cadNumber: "",
      locationLevel1: "",
      locationLevel2: "",
      station: "",
      days: null,
      begin: undefined,
      end: undefined,
      type: "",
      state: "",
      callSign: "",
    } as ICadEventsListFilter,
  }),
  getters: {
    getDistrictOptions(state): UnitArea[] {
      const regionStore = useRegionStore();
      return regionStore.getDistricts.filter(
        (district) => district.parentUnitCode === state.filters.locationLevel1
      );
    },

    getBrigadeOptions(state): UnitArea[] {
      const regionStore = useRegionStore();
      return regionStore.getStations.filter(
        (station) => station.parentUnitCode === state.filters.locationLevel2
      );
    },

    getOpenEvents(state): number {
      return state.events.filter(
        (event: ICadEventVm) =>
          event.eventOpenState === "OPEN" ||
          event.eventOpenState === "RE-OPENED"
      ).length;
    },

    // Handles sorting per field
    getSortedEvents(state): ICadEventVm[] {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.filteringActive = true;

      let sortedEvents: ICadEventVm[] = [];
      if (state.selectedSortOption.sortField === CADSORTFIELDS.DEFAULT) {
        sortedEvents = state.events;
      } else if (state.selectedSortOption.sortField === CADSORTFIELDS.CAD) {
        sortedEvents = _.chain(state.events)
          .orderBy("cadNumber", state.selectedSortOption.sortBy)
          .value();
      } else if (state.selectedSortOption.sortField === CADSORTFIELDS.TYPE) {
        sortedEvents = _.chain(state.events)
          .orderBy("eventTypeCode", state.selectedSortOption.sortBy)
          .value();
      } else if (state.selectedSortOption.sortField === CADSORTFIELDS.START) {
        sortedEvents = _.chain(state.events)
          .orderBy("addedDate", state.selectedSortOption.sortBy)
          .value();
      } else if (state.selectedSortOption.sortField === CADSORTFIELDS.ADDRESS) {
        sortedEvents = _.chain(state.events)
          .orderBy((event) => {
            // TODO check with FENZ whether we can separate unit number from addresses
            // in the meantime this performs the task of ignoring street no for sorting
            const reg = new RegExp(
              /(^\d+(\/+\d+)?\s*([A-Z](?![A-Z]))?(-+\d+(\/+\d+)?\s*([A-Z](?![A-Z]))?)?\s*)/i
            );
            const changedAddress = event.addressCombinedText?.replace(reg, "");
            return event.addressCombinedText ??
              state.selectedSortOption.sortBy === "asc"
              ? "zzz" + changedAddress
              : "aaa" + changedAddress;
          }, state.selectedSortOption.sortBy)
          .value();
      } else if (state.selectedSortOption.sortField === CADSORTFIELDS.RESULT) {
        sortedEvents = _.chain(state.events)
          .orderBy((event) => {
            return event.eventResultText ??
              state.selectedSortOption.sortBy === "asc"
              ? event.eventResultText
              : "000" + event.eventResultText;
          }, state.selectedSortOption.sortBy)
          .value();
      }

      loadingStateStore.filteringActive = false;
      return sortedEvents;
    },
  },
  actions: {
    // Events loading

    async loadOpenEventsFromApi() {
      // NOTE: includes state: "RE-OPENED" events
      const filters = { state: "OPEN" } as ICadEventsListFilter;
      this.events = await genericLoadingFunction(filters);
    },

    async loadFilteredEventsFromApi(/*add params for all filters*/) {
      this.events = await genericLoadingFunction(this.filters);
    },

    async loadCadEventTypesFromApi() {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.eventTypesLoading = true;
      const client: IIncrClient = new IncrClient();
      try {
        this.eventTypes = await client.getTypes();
      } catch {
        console.error("Error loading CAD event types");
      } finally {
        loadingStateStore.eventTypesLoading = false;
      }
    },

    updateFieldToSort(field: CADSORTOPTIONS) {
      if (field != this.selectedSortOption) {
        this.selectedSortOption = field;
      }
    },
  },
});
