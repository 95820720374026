import { useRegionStore } from "@/store/regions";
import { useCadEventStore } from "../store/icadEvents";
import { ICadEventDto } from "./icadEventVm";

export interface IApplianceDto {
  callSignCode: string;
  kCodeIdentifier?: keyof typeof KCODES;
  kCodeStatus?: string;
  updatedTimestamp: string;
  organisationUnitCodeLevelOne: string | null;
  organisationUnitCodeLevelTwo: string | null;
  stationIdentifier: string;
  redFleetIndicator: boolean;
  commonEventIdentifier?: number | null;
  cadNumber?: string;
  eventTypeCode?: string;
  eventTypeDescription?: string;
  specialLocationText?: string;
}

export interface IApplianceVm extends IApplianceDto {
  kCodeOrder?: KCODES;
  stationName: string;
  updatedDate: Date;
  cadEvent?: IApplianceCadData;
}

export interface IApplianceCadData {
  totalUnitsAssignedNumber: number;
  maximumUnitsAssignedNumber: number;
  incidentNoteText: string | null;
  xLocation?: number;
  yLocation?: number;
}

// prettier-ignore
export const KCODES = {
  "K55": 1,
  "K66": 2,
  "K77": 3,
  "K88": 4,
  "K99": 5,
  "K1": 6,
  "K2": 7,
  "K3": 8,
  "K4": 9,
  "K5": 10,
  "K6": 11,
  "K7": 13,
  "K9": 13,
  "K0": 14,

} as const;
export type KCODES = typeof KCODES[keyof typeof KCODES];

export function toApplianceVm(appliance: IApplianceDto): IApplianceVm {
  const regionStore = useRegionStore();
  const icadStore = useCadEventStore();
  return {
    ...appliance,
    updatedDate: new Date(appliance.updatedTimestamp),
    kCodeOrder: KCODES[appliance.kCodeIdentifier ?? "K1"],
    // TODO do we want to change this to a dictionary lookup of all station names?
    stationName:
      regionStore.getStations.find(
        (a) => appliance.stationIdentifier === a.unitCode
      )?.unitName ?? "",
    //TODO do we want to keep open events separately from the store for the search page...
    cadEvent: appliance.cadNumber
      ? cadEventToApplianceCadData(
          icadStore.events?.find((e) => e.cadNumber === appliance.cadNumber)
        )
      : undefined,
  };

  function cadEventToApplianceCadData(
    cadEvent: ICadEventDto | undefined
  ): IApplianceCadData | undefined {
    if (cadEvent == undefined) {
      return undefined;
    }
    return {
      totalUnitsAssignedNumber: cadEvent.totalUnitsAssignedNumber,
      maximumUnitsAssignedNumber: cadEvent.maximumUnitsAssignedNumber,
      incidentNoteText: cadEvent.incidentNoteText,
      xLocation: cadEvent.xCoordinateNumber,
      yLocation: cadEvent.yCoordinateNumber,
    };
  }
}
