
import NotificationTag, { NOTIFICATIONTYPES } from "../NotificationTag.vue";
import { computed, defineComponent, PropType, ref } from "vue";
import { useApplianceStore } from "@/store/appliances";

export type FilterCountType =
  | "getOnStationCount"
  | "getEnrouteCount"
  | "getOnSceneCount"
  | "getOnRoadCount"
  | "getUnavailableCount";

export default defineComponent({
  name: "SmartStatusFilter",
  components: { NotificationTag },
  props: {
    text: String,
    initialValue: Boolean,
    filterCountGet: {
      type: String as PropType<FilterCountType>,
      required: true,
    },
    showNumber: Boolean,
    numberColour: {
      type: String as PropType<NOTIFICATIONTYPES>,
      required: true,
    },
  },
  emits: ["filterToggled"],

  setup(props) {
    const applianceStore = useApplianceStore();
    const countComputed = computed(
      (): number => applianceStore[props.filterCountGet]
    );
    const isClicked = ref(props.initialValue);

    return {
      countComputed,
      isClicked,
    };
  },
});
