import { faL } from "@fortawesome/free-solid-svg-icons";
import { defineStore } from "pinia";

export type LoadingStateType =
  | "appliancesLoading"
  | "filteringActive"
  | "regionsLoading"
  | "eventsLoading"
  | "reportLoading"
  | "eventTypesLoading"
  | "getEventsScreenLoading"
  | "getApplianceTableLoading";

export const useLoadingStateStore = defineStore("loadingState", {
  state: () => ({
    appliancesLoading: false,
    filteringActive: false,
    regionsLoading: false,
    eventsLoading: false,
    reportLoading: false,
    eventTypesLoading: false,
  }),
  getters: {
    getApplianceTableLoading(state): boolean {
      return state.appliancesLoading || state.filteringActive;
    },
    getEventsScreenLoading(state): boolean {
      return state.eventsLoading || state.eventTypesLoading;
    },
  },
  actions: {},
});
