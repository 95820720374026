import { defineStore } from "pinia";
import _ from "lodash";
import {
  toApplianceVm,
  IApplianceVm,
  IApplianceDto,
} from "@/models/applianceVm";
import { useStorage } from "@vueuse/core";
import { UnitArea, useRegionStore } from "./regions";
import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import { useLoadingStateStore } from "./loadingState";
import { getUserAccountId } from "@/auth/getUserAccountId";
import { useCadEventStore } from "./icadEvents";

export const SORTBY = {
  ASC: "asc",
  DESC: "desc",
} as const;

export type SORTBY = typeof SORTBY[keyof typeof SORTBY];

const SORTFIELDS = {
  DEFAULT: "",
  ICAD: "ICAD Number",
  INCIDENT: "Incident type",
  UPDATED: "Last updated",
  KCODE: "Status",
  STATION: "Station",
} as const;

type SORTFIELDS = typeof SORTFIELDS[keyof typeof SORTFIELDS];

export const SORTOPTIONS = {
  DEFAULT: {
    sortField: SORTFIELDS.DEFAULT,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
  ICAD_ASC: {
    sortField: SORTFIELDS.ICAD,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.ICAD_DESC as SORTOPTIONS,
  },
  ICAD_DESC: {
    sortField: SORTFIELDS.ICAD,
    sortBy: SORTBY.DESC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
  INCIDENT_ASC: {
    sortField: SORTFIELDS.INCIDENT,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.INCIDENT_DESC as SORTOPTIONS,
  },
  INCIDENT_DESC: {
    sortField: SORTFIELDS.INCIDENT,
    sortBy: SORTBY.DESC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
  UPDATED_ASC: {
    sortField: SORTFIELDS.UPDATED,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.UPDATED_DESC as SORTOPTIONS,
  },
  UPDATED_DESC: {
    sortField: SORTFIELDS.UPDATED,
    sortBy: SORTBY.DESC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
  KCODE_ASC: {
    sortField: SORTFIELDS.KCODE,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.KCODE_DESC as SORTOPTIONS,
  },
  KCODE_DESC: {
    sortField: SORTFIELDS.KCODE,
    sortBy: SORTBY.DESC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
  STATION_ASC: {
    sortField: SORTFIELDS.STATION,
    sortBy: SORTBY.ASC,
    nextOption: () => SORTOPTIONS.STATION_DESC as SORTOPTIONS,
  },
  STATION_DESC: {
    sortField: SORTFIELDS.STATION,
    sortBy: SORTBY.DESC,
    nextOption: () => SORTOPTIONS.DEFAULT as SORTOPTIONS,
  },
};

export type SORTOPTIONS = typeof SORTOPTIONS[keyof typeof SORTOPTIONS];

interface IStatusFilters {
  onStation: boolean;
  enroute: boolean;
  onScene: boolean;
  onRoad: boolean;
  unavailable: boolean;
}
interface IApplianceFilters {
  region: UnitArea;
  district: UnitArea;
  status: IStatusFilters;
  redFleet: boolean;
  whiteFleet: boolean;
}

const filterApplianceByArea = (
  appliance: IApplianceDto,
  region: UnitArea,
  district: UnitArea
): boolean => {
  return (
    (!region.unitCode ||
      appliance.organisationUnitCodeLevelOne === region.unitCode) &&
    (!district.unitCode ||
      appliance.organisationUnitCodeLevelTwo === district.unitCode)
  );
};

const filterApplianceByFleet = (
  appliance: IApplianceDto,
  redFleet: boolean,
  whiteFleet: boolean
): boolean => {
  return (
    (redFleet && appliance.redFleetIndicator) ||
    (whiteFleet && !appliance.redFleetIndicator)
  );
};

const filterApplianceByStatus = (
  appliance: IApplianceDto,
  filters: IStatusFilters
): boolean => {
  if (filters.onStation && applianceOnStation(appliance)) return true;
  if (filters.enroute && applianceEnroute(appliance)) return true;
  if (filters.onScene && applianceOnScene(appliance)) return true;
  if (filters.onRoad && applianceOnRoad(appliance)) return true;
  if (filters.unavailable && applianceUnavailable(appliance)) return true;
  return false;
};
const applianceOnStation = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K7" || appliance.kCodeIdentifier == "K9";
const applianceEnroute = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K1";
const applianceOnScene = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K2" ||
  appliance.kCodeIdentifier == "K5" ||
  appliance.kCodeIdentifier == "K55" ||
  appliance.kCodeIdentifier == "K66" ||
  appliance.kCodeIdentifier == "K77" ||
  appliance.kCodeIdentifier == "K88" ||
  appliance.kCodeIdentifier == "K99";
const applianceOnRoad = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K3" ||
  appliance.kCodeIdentifier == "K4" ||
  appliance.kCodeIdentifier == "K6";
const applianceUnavailable = (appliance: IApplianceDto) =>
  appliance.kCodeIdentifier == "K0";

interface IGroupedAppliance {
  stationName: string;
  appliances: IApplianceVm[];
  key: string;
}
const groupApplianceBy = (
  appliances: IApplianceVm[],
  keyGenerator: (appliance: IApplianceVm) => string,
  sortByDirection: SORTBY,
  type: string
): IGroupedAppliance[] => {
  // TODO move toApplianceVm. May not work,
  // try load all events and then appliances, then map in data load
  return _.chain(appliances)
    .groupBy(keyGenerator)
    .map((value, key) => {
      const keys = key.split("|");
      let sortByValue: string | number | Date = keys[1];
      if (type === "number") {
        sortByValue = +keys[1];
      } else if (type === "date") {
        sortByValue = value[0].updatedTimestamp;
      }
      return {
        stationName: keys[0],
        sortBy: sortByValue,
        appliances: value,
        key: key,
      };
    })
    .orderBy(["sortBy", "stationName"], [sortByDirection, "asc"])
    .value();
};

const applianceVmSerializer = {
  read: (a: string) => {
    const dtoList: IApplianceDto[] = JSON.parse(a);
    return dtoList.map(toApplianceVm);
  },
  write: (a: IApplianceVm[]) => JSON.stringify(a),
};

export const useApplianceStore = defineStore("appliances", {
  state: () => ({
    appliances: useStorage("appliance-data", [] as IApplianceVm[], undefined, {
      serializer: applianceVmSerializer,
    }),
    filteredAppliancesByArea: useStorage(
      "appliance-data-area",
      [] as IApplianceVm[],
      undefined,
      {
        serializer: applianceVmSerializer,
      }
    ),
    filteredAppliances: useStorage(
      "appliance-data-filtered",
      [] as IApplianceVm[],
      undefined,
      {
        serializer: applianceVmSerializer,
      }
    ),
    pollTimeSeconds: 30,
    pollBufferTimeSeconds: 5,
    lastUpdatedAppliances: useStorage<Date | undefined>(
      "appliance-updated",
      undefined,
      undefined,
      {
        serializer: {
          read: (v: string) => (v ? new Date(v) : undefined),
          write: (v: Date) => (v ? v.toISOString() : ""),
        },
      }
    ),
    selectedSortOption: SORTOPTIONS.DEFAULT as SORTOPTIONS,
    filters: useStorage("appliance-filters", {
      region: {} as UnitArea,
      district: {} as UnitArea,
      status: {
        onStation: true,
        enroute: true,
        onScene: true,
        onRoad: true,
        unavailable: true,
      },
      redFleet: true,
      whiteFleet: true,
    } as IApplianceFilters),
  }),
  getters: {
    // Handles filter/sort per field
    // TODO how can we speed up this sorting???
    appliancesFilteredAndSorted(state): IGroupedAppliance[] {
      let fieldDataType = "";

      let keyGenerator = (a: IApplianceVm) => {
        return `${a.stationName}|${a.stationIdentifier}`;
      };

      if (state.selectedSortOption.sortField === SORTFIELDS.ICAD) {
        keyGenerator = (a) =>
          `${a.stationName}|${
            a.cadNumber ??
            (state.selectedSortOption.sortBy === SORTBY.ASC ? "Z" : "A")
          }`;
      } else if (state.selectedSortOption.sortField === SORTFIELDS.INCIDENT) {
        keyGenerator = (a) =>
          `${a.stationName}|${
            a.eventTypeDescription ??
            (state.selectedSortOption.sortBy === SORTBY.ASC ? "Z" : "A")
          }`;
      } else if (state.selectedSortOption.sortField === SORTFIELDS.UPDATED) {
        fieldDataType = "date";
        keyGenerator = (a) => `${a.stationName}|${a.updatedTimestamp}`;
      } else if (state.selectedSortOption.sortField === SORTFIELDS.KCODE) {
        fieldDataType = "number";
        keyGenerator = (a) => `${a.stationName}|${a.kCodeOrder}`;
      } else if (state.selectedSortOption.sortField === SORTFIELDS.STATION) {
        keyGenerator = (a) => `${a.stationName}|${a.stationName}`;
      }

      const groupedAppliances = groupApplianceBy(
        state.filteredAppliances,
        keyGenerator,
        state.selectedSortOption.sortBy,
        fieldDataType
      );
      return groupedAppliances;
    },

    getRegionFilterName(state): string {
      if (state.filters.district?.unitCode)
        return state.filters.district.unitName;
      return state.filters.region?.unitName;
    },

    getOnStationCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnStation(a)
      ).length;
      return count;
    },
    getEnrouteCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceEnroute(a)
      ).length;
      return count;
    },
    getOnSceneCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnScene(a)
      ).length;
      return count;
    },
    getOnRoadCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceOnRoad(a)
      ).length;
      return count;
    },
    getUnavailableCount(): number {
      const count = this.filteredAppliancesByArea.filter((a) =>
        applianceUnavailable(a)
      ).length;
      return count;
    },
    getAreaFilteredCount(): number {
      const count = this.filteredAppliancesByArea.length;
      return count;
    },
  },
  actions: {
    async loadAppliancesFromApi() {
      const loadingStateStore = useLoadingStateStore();
      const regionStore = useRegionStore();
      const cadEventStore = useCadEventStore();
      loadingStateStore.appliancesLoading = true;
      const client: IIncrClient = new IncrClient();
      const newUpdateTime = new Date();
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(newUpdateTime.getDate() - 3);
      if (
        this.lastUpdatedAppliances &&
        threeDaysAgo < this.lastUpdatedAppliances
      ) {
        // TODO need to load in the regions better...
        await regionStore.loadRegionsFromApi();
        await this.updateAppliancesFromApi(true);
        this.applyFiltering();
        loadingStateStore.appliancesLoading = false;
        return;
      }
      try {
        const accountId = await getUserAccountId();
        let newAppliances: IApplianceDto[] = [];
        await Promise.all([
          regionStore.loadRegionsFromApi(),
          cadEventStore.loadOpenEventsFromApi(),
          (newAppliances = await client.getAppliancesList(accountId)),
        ]);
        //Refresh all data
        this.appliances = newAppliances.map(toApplianceVm);
        this.applyFiltering();
        this.lastUpdatedAppliances = newUpdateTime;
      } catch (error) {
        console.error("Failed loading appliance data from API", error);
      } finally {
        loadingStateStore.appliancesLoading = false;
      }
    },

    async updateAppliancesFromApi(updateFromDate?: boolean) {
      const client: IIncrClient = new IncrClient();
      const newUpdateTIme = new Date();
      const cadEventStore = useCadEventStore();
      try {
        const accountId = await getUserAccountId();
        let newAppliances: IApplianceDto[] = [];
        await Promise.allSettled([
          cadEventStore.loadOpenEventsFromApi(),
          (newAppliances = await client.getAppliancesList(
            accountId,
            !updateFromDate
              ? this.pollTimeSeconds + this.pollBufferTimeSeconds
              : undefined,
            updateFromDate ? this.lastUpdatedAppliances : undefined
          )),
        ]);
        //Update data in place
        if (!newAppliances?.length) {
          return;
        }
        for (const newAppliance of newAppliances) {
          const index = this.appliances.findIndex(
            (a) => a.callSignCode === newAppliance.callSignCode
          );
          if (index === -1) {
            this.appliances.push(toApplianceVm(newAppliance));
            continue;
          }
          this.appliances.splice(index, 1, toApplianceVm(newAppliance));
        }
        this.applyFiltering(true);
        if (this.lastUpdatedAppliances)
          this.lastUpdatedAppliances = newUpdateTIme;
      } catch (error) {
        console.error("Failed updating appliance data from API", error);
      }
    },

    // TODO attempt web workers instead of using setTimeout
    applyFiltering(silentFilter?: boolean) {
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.appliancesLoading = !silentFilter;
      setTimeout(() => {
        const areaFilteredAppliances = this.appliances.filter(
          (a) =>
            filterApplianceByArea(
              a,
              this.filters.region,
              this.filters.district
            ) &&
            filterApplianceByFleet(
              a,
              this.filters.redFleet,
              this.filters.whiteFleet
            )
        );
        this.filteredAppliancesByArea = areaFilteredAppliances;
        this.filteredAppliances = areaFilteredAppliances.filter((a) =>
          filterApplianceByStatus(a, this.filters.status)
        );
        loadingStateStore.appliancesLoading = false;
      }, 50);
    },

    updateFieldToSort(field: SORTOPTIONS) {
      if (field != this.selectedSortOption) {
        this.selectedSortOption = field;
      }
    },

    updateRegionFilter(region: UnitArea) {
      this.filters.region = region;
      this.filters.district = {} as UnitArea;
      this.applyFiltering();
    },

    updateDistrictFilter(district: UnitArea) {
      this.filters.district = district;
      this.applyFiltering();
    },

    toggleOnStationFilter() {
      this.filters.status.onStation = !this.filters.status.onStation;
      this.applyFiltering();
    },
    toggleEnrouteFilter() {
      this.filters.status.enroute = !this.filters.status.enroute;
      this.applyFiltering();
    },
    toggleOnSceneFilter() {
      this.filters.status.onScene = !this.filters.status.onScene;
      this.applyFiltering();
    },
    toggleOnRoadFilter() {
      this.filters.status.onRoad = !this.filters.status.onRoad;
      this.applyFiltering();
    },
    toggleUnavailableFilter() {
      this.filters.status.unavailable = !this.filters.status.unavailable;
      this.applyFiltering();
    },

    toggleRedFleetFilter() {
      this.filters.redFleet = !this.filters.redFleet;
      this.applyFiltering();
    },

    toggleWhiteFleetFilter() {
      this.filters.whiteFleet = !this.filters.whiteFleet;
      this.applyFiltering();
    },
  },
});
