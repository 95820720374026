
import { defineComponent, PropType, computed } from "vue";
import { SORTBY } from "@/store/appliances";

export default defineComponent({
  name: "GenericTableHeaderButton",
  emits: ["fieldClicked"],
  props: {
    label: { type: String, required: true },
    sortable: Boolean,
    widthPercent: Number,
    sortDirection: { type: String as PropType<SORTBY | null> },
  },

  setup(props, context) {
    // #region data
    // #endregion

    // # region methods

    const emitSortBy = () => {
      context.emit("fieldClicked");
    };

    const sortIcon = computed(() => {
      if (props.sortDirection) {
        return props.sortDirection === SORTBY.ASC ? "sort-asc" : "sort-desc";
      }
      return "sort";
    });

    // #endregion

    return {
      emitSortBy,
      sortIcon,
    };
  },
});
