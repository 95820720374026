export interface ICadEventDto {
  totalUnitsAssignedNumber: number;
  updatedTimestamp?: string;
  xCoordinateNumber: number;
  yCoordinateNumber: number;
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp: string;
  addedToSystemTimestamp: string;
  addressCombinedText?: string;
  agencyEventRevisionTimestamp: string;
  agencyEventUpdatedTimestamp?: string;
  emergencyServicesZoneIdentifier: number;
  eventClass: string | null;
  eventOpenState: string;
  eventReopenedIndicator: boolean;
  eventStatusLastChangedTimestamp: string;
  eventTypeCode: string;
  eventTypeDescription: string;
  firstDataEntryTimestamp: string;
  incidentNoteText: string | null;
  latestEventCommentTimestamp?: string;
  openEventIndicator: boolean;
  organisationUnitCodeLevelOne: string;
  organisationUnitCodeLevelTwo: string;
  respondingType: boolean;
  revisionTimestamp: string;
  rowIdentity: number;
  stationIdentifier: string;
  eventResultText?: string;
  firstUnitArrivedTimestamp?: string;
  firstUnitDispatchedTimestamp?: string;
  firstUnitEnRouteTimestamp?: string;
  latestKCodeLogTimestamp?: string;
  latestUnitHistoryTimestamp?: string;
  maximumUnitsAssignedNumber: number;
}

export interface IEventTypeDto {
  eventTypeCode: string;
  eventTypeDescription: string;
  eventClass: string;
  isResponding: boolean;
}

export interface ICadEventVm extends ICadEventDto {
  addedDate: Date;
}

export function toCadEventVm(event: ICadEventDto): ICadEventVm {
  return {
    ...event,
    addedDate: new Date(event.addedTimestamp),
  };
}
