import { ICadEventVm } from "@/models/icadEventVm";

export const generateMapLink = (x: number, y: number): string => {
  // TODO implement this....
  return "#";
};

export const generateReportLink = (cadNumber: string): string => {
  return `/report/${cadNumber}`;
};
