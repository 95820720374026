import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExternalLinkAlt,
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faSlidersH,
  faTimes,
  faSort,
  faSortAsc,
  faSortDesc,
  faMapMarkerAlt,
  faDownload,
  faPrint,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

library.add(faExternalLinkAlt);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faPlus);
library.add(faMinus);
library.add(faSlidersH);
library.add(faTimes);
library.add(faSort);
library.add(faMapMarkerAlt);
library.add(faDownload);
library.add(faPrint);
library.add(faCommentAlt);
library.add(faSortAsc);
library.add(faSortDesc);

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(msalPlugin, msalInstance);
app.component("font-awesome-icon", FontAwesomeIcon);
router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
