
import { computed, defineComponent, PropType } from "vue";
import { FingerprintSpinner } from "epic-spinners";
import { LoadingStateType, useLoadingStateStore } from "@/store/loadingState";

export default defineComponent({
  name: "LoadingSpinner",
  components: {
    FingerprintSpinner,
  },
  props: {
    loadingState: {
      type: String as PropType<LoadingStateType>,
      required: true,
    },
  },

  setup(props) {
    const loadingStateStore = useLoadingStateStore();

    const showLoadingSpinner = computed(
      (): boolean => loadingStateStore[props.loadingState]
    );
    return {
      showLoadingSpinner,
    };
  },
});
