interface ICadEventSummaryResultDto {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp: string;
  addressCombinedText: string;
  alarmIdentifier: string;
  callerName: string;
  callSourceCode: string;
  emergencyServicesZoneIdentifier: number;
  eventResultText: string;
  eventTypeCode: string;
  eventTypeDescription: string;
  incidentNoteText: string | null;
  premisesName: string;
  stationIdentifier: string;
  xCoordinateNumber: number;
  yCoordinateNumber: number;
  callerPhoneNumber: string;
}

interface ICadEventCommentResultsDto {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp: string;
  commentText: string;
  eventCommentIdentifier: number;
  rowIdentity: number;
  systemMessageUniqueIdentifier: number;
  systemCommentIndicator: number;
}

interface ICadEventHistoryResultDto {
  commonEventIdentifier: number;
  cadNumber: string;
  alertedTimestamp: string;
  closedTimestamp: string;
  stopTimestamp: string;
  createdTimestamp: string;
  confirmedTimestamp: string;
  firstArrivalTimestamp: string;
  firstResponseTimestamp: string;
  secondArrivalTimestamp: string;
  thirdArrivalTimestamp: string;
}

interface ICadEventNotificationResultsDto {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp: string;
  notificationCommentText: string;
  eventCommentIdentifier: number;
  eventCommentTypeIdentifier: number;
  rowIdentity: number;
  systemCommentIndicator: number;
  systemMessageUniqueIdentifier: number;
}

interface ICadEventCoverMoveResultsDto {
  commonEventIdentifier: number;
  cadNumber: string;
  callSignCode: string;
  startTimestamp: string;
  endTimestamp: string;
}

interface ICadEventDeployedResponseResultsDto {
  commonEventIdentifier: number;
  cadNumber: string;
  callSignCode: string;
  departedTimestamp?: string;
  dispatchedAlarmLevelNumber: number;
  dispatchedTimestamp?: string;
  dispatchGroupCode: string;
  locationName: string;
  responseIdentifier: number;
  responseTimestamp?: string;
  stationIdentifier: string;
  turnoutTimestamp?: string;
  unitCurrentStatusCode: string;
  unitTypeCode: string;
  vehicleRegistrationNumber: string;
}

export interface IIncidentReportDto {
  cadEventSummaryResult: ICadEventSummaryResultDto;
  cadEventCommentResults: ICadEventCommentResultsDto[];
  cadEventHistoryResult: ICadEventHistoryResultDto;
  cadEventNotificationResults: ICadEventNotificationResultsDto[];
  cadEventCoverMoveResults: ICadEventCoverMoveResultsDto[];
  cadEventDeployedResponseResults: ICadEventDeployedResponseResultsDto[];
}

interface ICadEventSummaryResultVm {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp?: Date;
  addressCombinedText: string;
  alarmIdentifier: string;
  callerName: string;
  callSourceCode: string;
  emergencyServicesZoneIdentifier: number;
  eventResultText: string;
  eventTypeCode: string;
  eventTypeDescription: string;
  incidentNoteText: string;
  premisesName: string;
  stationIdentifier: string;
  xCoordinateNumber: number;
  yCoordinateNumber: number;
  callerPhoneNumber: string;
}

interface ICadEventCommentResultsVm {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp?: Date;
  commentText: string;
  eventCommentIdentifier: number;
  rowIdentity: number;
  systemMessageUniqueIdentifier: number;
  systemCommentIndicator: number;
  commentRead: boolean;
}

interface ICadEventHistoryResultVm {
  commonEventIdentifier: number;
  cadNumber: string;
  alertedTimestamp?: Date;
  closedTimestamp?: Date;
  createdTimestamp?: Date;
  stopTimestamp?: Date;
  confirmedTimestamp?: Date;
  firstArrivalTimestamp?: Date;
  firstResponseTimestamp?: Date;
  secondArrivalTimestamp?: Date;
  thirdArrivalTimestamp?: Date;
}

interface ICadEventNotificationResultsVm {
  commonEventIdentifier: number;
  cadNumber: string;
  addedTimestamp?: Date;
  notificationCommentText: string;
  eventCommentIdentifier: number;
  eventCommentTypeIdentifier: number;
  rowIdentity: number;
  systemCommentIndicator: number;
  systemMessageUniqueIdentifier: number;
  notifcationRead: boolean;
}

interface ICadEventCoverMoveResultsVm {
  commonEventIdentifier: number;
  cadNumber: string;
  callSignCode: string;
  startTimestamp?: Date;
  endTimestamp?: Date;
}

interface ICadEventDeployedResponseResultsVm {
  commonEventIdentifier: number;
  cadNumber: string;
  callSignCode: string;
  departedTimestamp?: Date;
  dispatchedAlarmLevelNumber: number;
  dispatchedTimestamp?: Date;
  dispatchGroupCode: string;
  locationName: string;
  responseIdentifier: number;
  responseTimestamp?: Date;
  stationIdentifier: string;
  turnoutTimestamp?: Date;
  unitCurrentStatusCode: string;
  unitTypeCode: string;
  vehicleRegistrationNumber: string;
}

export interface IIncidentReportVm {
  cadEventSummaryResult: ICadEventSummaryResultVm;
  cadEventCommentResults: ICadEventCommentResultsVm[];
  cadEventHistoryResult: ICadEventHistoryResultVm;
  cadEventNotificationResults: ICadEventNotificationResultsVm[];
  cadEventCoverMoveResults: ICadEventCoverMoveResultsVm[];
  cadEventDeployedResponseResults: ICadEventDeployedResponseResultsVm[];
}

export function toIIncidentReportVm(
  report: IIncidentReportDto,
  isUpdate?: boolean
): IIncidentReportVm {
  return {
    cadEventSummaryResult: toCadEventSummaryVm(report.cadEventSummaryResult),
    cadEventHistoryResult: toCadEventHistoryVm(report.cadEventHistoryResult),
    cadEventCommentResults: report.cadEventCommentResults
      ?.map((comment) => toCadEventCommentVm(comment, isUpdate))
      .sort((a, b) => {
        return (
          (b.addedTimestamp?.getTime() ?? 0) -
          (a.addedTimestamp?.getTime() ?? 0)
        );
      }),
    cadEventNotificationResults: report.cadEventNotificationResults
      ?.map((notification) =>
        toCadEventNotificationResultsVm(notification, isUpdate)
      )
      .sort((a, b) => {
        return (
          (b.addedTimestamp?.getTime() ?? 0) -
          (a.addedTimestamp?.getTime() ?? 0)
        );
      }),
    cadEventCoverMoveResults: report.cadEventCoverMoveResults
      ?.map((coverMove) => toCadEventCoverMoveResultsVm(coverMove))
      .sort((a, b) => {
        return (
          (b.startTimestamp?.getTime() ?? 0) -
          (a.startTimestamp?.getTime() ?? 0)
        );
      }),
    cadEventDeployedResponseResults: report.cadEventDeployedResponseResults
      ?.map((response) => toCadEventDeployedResponseResultsVm(response))
      .sort((a, b) => {
        return (
          (b.dispatchedTimestamp?.getTime() ?? 0) -
          (a.dispatchedTimestamp?.getTime() ?? 0)
        );
      }),
  };
}

function toCadEventSummaryVm(
  eventSummary: ICadEventSummaryResultDto
): ICadEventSummaryResultVm {
  return {
    ...eventSummary,
    addedTimestamp: dateFromString(eventSummary.addedTimestamp),
    incidentNoteText: eventSummary.incidentNoteText ?? "",
  };
}
function toCadEventCommentVm(
  eventComment: ICadEventCommentResultsDto,
  isUpdate?: boolean
): ICadEventCommentResultsVm {
  return {
    ...eventComment,
    addedTimestamp: dateFromString(eventComment.addedTimestamp),
    commentRead: !isUpdate,
  };
}
function toCadEventHistoryVm(
  eventHistory: ICadEventHistoryResultDto
): ICadEventHistoryResultVm {
  return {
    commonEventIdentifier: eventHistory.commonEventIdentifier,
    cadNumber: eventHistory.cadNumber,
    alertedTimestamp: dateFromString(eventHistory.alertedTimestamp),
    closedTimestamp: dateFromString(eventHistory.closedTimestamp),
    stopTimestamp: dateFromString(eventHistory.stopTimestamp),
    createdTimestamp: dateFromString(eventHistory.createdTimestamp),
    confirmedTimestamp: dateFromString(eventHistory.confirmedTimestamp),
    firstArrivalTimestamp: dateFromString(eventHistory.firstArrivalTimestamp),
    firstResponseTimestamp: dateFromString(eventHistory.firstResponseTimestamp),
    secondArrivalTimestamp: dateFromString(eventHistory.secondArrivalTimestamp),
    thirdArrivalTimestamp: dateFromString(eventHistory.thirdArrivalTimestamp),
  };
}
function toCadEventNotificationResultsVm(
  eventNotification: ICadEventNotificationResultsDto,
  isUpdate?: boolean
): ICadEventNotificationResultsVm {
  return {
    ...eventNotification,
    addedTimestamp: dateFromString(eventNotification.addedTimestamp),
    notifcationRead: !isUpdate,
  };
}
function toCadEventCoverMoveResultsVm(
  eventCover: ICadEventCoverMoveResultsDto
): ICadEventCoverMoveResultsVm {
  return {
    ...eventCover,
    startTimestamp: dateFromString(eventCover.startTimestamp),
    endTimestamp: dateFromString(eventCover.endTimestamp),
  };
}
function toCadEventDeployedResponseResultsVm(
  eventDeployedResponse: ICadEventDeployedResponseResultsDto
): ICadEventDeployedResponseResultsVm {
  return {
    ...eventDeployedResponse,
    departedTimestamp: dateFromString(eventDeployedResponse.departedTimestamp),
    dispatchedTimestamp: dateFromString(
      eventDeployedResponse.dispatchedTimestamp
    ),
    responseTimestamp: dateFromString(eventDeployedResponse.responseTimestamp),
    turnoutTimestamp: dateFromString(eventDeployedResponse.turnoutTimestamp),
  };
}

function dateFromString(dateString?: string): Date | undefined {
  return dateString ? new Date(dateString) : undefined;
}
