
import GenericTableHeaderButton from "../GenericTableHeaderButton.vue";
import SmartStatusAppliancesTableAppliance from "./SmartStatusAppliancesTableAppliance.vue";
import { defineComponent, computed } from "vue";
import { useApplianceStore, SORTOPTIONS, SORTBY } from "@/store/appliances";
import LoadingSpinner from "../LoadingSpinner.vue";

export default defineComponent({
  name: "SmartStatusAppliancesTable",
  components: {
    GenericTableHeaderButton,
    SmartStatusAppliancesTableAppliance,
    LoadingSpinner,
  },
  props: {
    selectedRegion: String,
  },

  setup() {
    // #region data

    const applianceStore = useApplianceStore();

    // #endregion

    // #region methods
    const getSortDirection = (field: SORTOPTIONS) => {
      if (applianceStore.selectedSortOption.sortField === field.sortField) {
        return applianceStore.selectedSortOption.sortBy;
      }
      return null;
    };

    const getLabelForSelectOption = (field: SORTOPTIONS) => {
      return `${field.sortField} ${field.sortBy === SORTBY.ASC ? "▲" : "▼"}`;
    };

    const sortBy = (fieldClicked: SORTOPTIONS) => {
      if (
        applianceStore.selectedSortOption.sortField === fieldClicked.sortField
      ) {
        applianceStore.updateFieldToSort(
          applianceStore.selectedSortOption.nextOption()
        );
      } else {
        applianceStore.updateFieldToSort(fieldClicked);
      }
    };

    const sortByModel = computed({
      // getter
      get() {
        return applianceStore.selectedSortOption;
      },
      // setter
      set(newField: SORTOPTIONS) {
        applianceStore.updateFieldToSort(newField);
      },
    });

    return {
      applianceStore,
      sortBy,
      getSortDirection,
      defaultSort: SORTOPTIONS.DEFAULT,
      stationSortAsc: SORTOPTIONS.STATION_ASC,
      stationSortDesc: SORTOPTIONS.STATION_DESC,
      kCodeSortAsc: SORTOPTIONS.KCODE_ASC,
      kCodeSortDesc: SORTOPTIONS.KCODE_DESC,
      iCadSortAsc: SORTOPTIONS.ICAD_ASC,
      iCadSortDesc: SORTOPTIONS.ICAD_DESC,
      incidentSortAsc: SORTOPTIONS.INCIDENT_ASC,
      incidentSortDesc: SORTOPTIONS.INCIDENT_DESC,
      updatedSortAsc: SORTOPTIONS.UPDATED_ASC,
      updatedSortDesc: SORTOPTIONS.UPDATED_DESC,
      sortByModel,
      getLabelForSelectOption,
    };

    // #endregion
  },
});
