
import { computed, defineComponent, PropType } from "vue";

export const NOTIFICATIONTYPES = {
  ONSTATION: "on-station",
  ENROUTE: "enroute",
  ONSCENE: "on-scene",
  ONROAD: "on-road",
  NOTIFICATION: "notification",
  GREEN: "green",
  RED: "red",
  BLACK: "black",
  GREY: "grey",
  UNAVAILABLE: "unavailable",
} as const;
// eslint-disable-next-line no-redeclare
export type NOTIFICATIONTYPES =
  typeof NOTIFICATIONTYPES[keyof typeof NOTIFICATIONTYPES];
export default defineComponent({
  name: "NotificationTag",
  props: {
    value: { type: String, default: "" },
    type: { type: String as PropType<NOTIFICATIONTYPES>, required: true },
  },

  setup(props) {
    const statusClass = computed(() => {
      switch (props.type) {
        case NOTIFICATIONTYPES.NOTIFICATION:
        case NOTIFICATIONTYPES.ONROAD:
        case NOTIFICATIONTYPES.GREEN:
          return "green";
        case NOTIFICATIONTYPES.ONSCENE:
        case NOTIFICATIONTYPES.ENROUTE:
        case NOTIFICATIONTYPES.RED:
          return "red";
        case NOTIFICATIONTYPES.ONSTATION:
        case NOTIFICATIONTYPES.BLACK:
          return "black";
        case NOTIFICATIONTYPES.UNAVAILABLE:
        default:
          return "grey";
      }
    });

    const showTag = computed(
      () => parseInt(props.value) > 0 || props.value !== ""
    );

    return {
      showTag,
      statusClass,
    };
  },
});
