import { getUserAccountId } from "@/auth/getUserAccountId";
import { IIncrClient, IncrClient } from "@/data/incrApiClient";
import {
  IIncidentReportVm,
  toIIncidentReportVm,
} from "@/models/incidentReportVm";
import { defineStore } from "pinia";
import { useLoadingStateStore } from "./loadingState";

export const useReportStore = defineStore("report", {
  state: () => ({
    cadNumber: "",
    report: {} as IIncidentReportVm,
    pollTimeSeconds: 30,
    pollBufferTimeSeconds: 5,
    errorWithData: false,
  }),
  getters: {
    getUnreadMessageCount(state): number {
      return (
        state.report.cadEventCommentResults?.filter(
          (comment) => !comment.commentRead
        ).length ?? 0
      );
    },
    getUnreadNotificationCount(state): number {
      return (
        state.report.cadEventNotificationResults?.filter(
          (notification) => !notification.notifcationRead
        ).length ?? 0
      );
    },
  },
  actions: {
    async loadReportFromApi(cadNumber: string) {
      this.cadNumber = cadNumber;
      const client: IIncrClient = new IncrClient();
      const loadingStateStore = useLoadingStateStore();
      loadingStateStore.reportLoading = true;

      try {
        const accountId = await getUserAccountId();
        const data = await client.incidentReport(accountId, this.cadNumber);
        this.report = toIIncidentReportVm(data);
      } catch (error) {
        this.errorWithData = true;
      } finally {
        loadingStateStore.reportLoading = false;
      }
    },
    async updateReportData() {
      const client: IIncrClient = new IncrClient();
      try {
        const accountId = await getUserAccountId();
        const reportUpdate = await client.incidentReport(
          accountId,
          this.cadNumber,
          this.pollTimeSeconds + this.pollBufferTimeSeconds
        );

        const updatedReport = toIIncidentReportVm(reportUpdate, true);
        this.report.cadEventSummaryResult = updatedReport.cadEventSummaryResult;
        this.report.cadEventHistoryResult = updatedReport.cadEventHistoryResult;
        this.report.cadEventCommentResults.unshift(
          ...updatedReport.cadEventCommentResults.filter(
            (newComment) =>
              this.report.cadEventCommentResults.findIndex(
                (oldComment) =>
                  oldComment.eventCommentIdentifier ===
                  newComment.eventCommentIdentifier
              ) == -1
          )
        );
        this.report.cadEventCoverMoveResults.unshift(
          ...updatedReport.cadEventCoverMoveResults.filter(
            (newComment) =>
              this.report.cadEventCoverMoveResults.findIndex(
                (oldComment) =>
                  oldComment.callSignCode === newComment.callSignCode
              ) == -1
          )
        );
        this.report.cadEventDeployedResponseResults.unshift(
          ...updatedReport.cadEventDeployedResponseResults.filter(
            (newComment) =>
              this.report.cadEventDeployedResponseResults.findIndex(
                (oldComment) =>
                  oldComment.responseIdentifier ===
                  newComment.responseIdentifier
              ) == -1
          )
        );
        this.report.cadEventNotificationResults.unshift(
          ...updatedReport.cadEventNotificationResults.filter(
            (newComment) =>
              this.report.cadEventNotificationResults.findIndex(
                (oldComment) =>
                  oldComment.eventCommentIdentifier ===
                  newComment.eventCommentIdentifier
              ) == -1
          )
        );
      } catch (error) {
        console.log("failed to update data...");
      }
    },

    markMessagesAsRead() {
      for (
        let index = 0;
        index < this.report.cadEventCommentResults.length;
        index++
      ) {
        const element = this.report.cadEventCommentResults[index];
        if (element.commentRead) break;
        element.commentRead = true;
      }
    },
    markNotificationsAsRead() {
      for (
        let index = 0;
        index < this.report.cadEventNotificationResults.length;
        index++
      ) {
        const element = this.report.cadEventNotificationResults[index];
        if (element.notifcationRead) break;
        element.notifcationRead = true;
      }
    },
  },
});
