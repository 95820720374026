
import SmartStatusRegionfilter from "../components/SmartStatus/SmartStatusRegionfilter.vue";
import SmartStatusAppliances from "../components/SmartStatus/SmartStatusAppliances.vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useApplianceStore } from "@/store/appliances";

export default defineComponent({
  name: "SmartStatus",
  components: {
    SmartStatusRegionfilter,
    SmartStatusAppliances,
  },

  setup() {
    const pollId = ref(0);
    const applianceStore = useApplianceStore();
    applianceStore.loadAppliancesFromApi();

    document.title = "Smart Status";

    onMounted(() => {
      pollId.value = setInterval(async () => {
        await applianceStore.updateAppliancesFromApi();
      }, applianceStore.pollTimeSeconds * 1000);
    });
    onUnmounted(() => {
      clearInterval(pollId.value);
    });
  },
});
