
import SmartStatusAppliancesFilter from "./SmartStatusAppliancesFilter.vue";
import SmartStatusAppliancesTable from "./SmartStatusAppliancesTable.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SmartStatusAppliances",
  components: {
    SmartStatusAppliancesFilter,
    SmartStatusAppliancesTable,
  },
});
