
import { defineComponent, PropType } from "vue";
import Tooltip from "./Tooltip.vue";
import { IApplianceCadData } from "@/models/applianceVm";

export default defineComponent({
  name: "IncidentPopover",
  components: { Tooltip },
  props: {
    callSignCode: String,
    kCodeIdentifier: String,
    cadEvent: Object as PropType<IApplianceCadData>,
    popoverId: String,
  },
});
