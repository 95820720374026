import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "region-menu" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["region-menu__menu__region-header d-flex flex-row justify-content-between align-items-center", { open: _ctx.isOpen, selected: _ctx.isOpen && !_ctx.selectedDistrict }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectRegion()))
    }, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.region ? _ctx.region.unitName : ""), 1),
      (_ctx.showShouldIcon)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: "plus",
            class: "region-menu__menu__icon"
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["region-menu__menu__subregion-menu d-flex flex-column", { open: _ctx.isOpen }])
    }, [
      (_ctx.districts?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districts, (district) => {
              return (_openBlock(), _createElementBlock("button", {
                key: district.unitCode,
                class: _normalizeClass(["region-menu__menu__subregion-label d-flex", { selected: _ctx.selectedDistrict === district.unitCode }]),
                onClick: ($event: any) => (_ctx.onSelectDistrict(district))
              }, [
                _createElementVNode("p", null, _toDisplayString(district.unitName), 1)
              ], 10, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}