export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, "0");
}

export function isToday(dateToCheck: Date): boolean {
  const today = new Date();
  return (
    dateToCheck.getDate() == today.getDate() &&
    dateToCheck.getMonth() == today.getMonth() &&
    dateToCheck.getFullYear() == today.getFullYear()
  );
}

export function formatApplianceDate(date?: Date): string {
  if (!date) return "";
  if (isToday(date)) {
    return date.toLocaleTimeString("en-NZ");
  }
  return `${date.toLocaleDateString("en-NZ")} - ${date.toLocaleTimeString(
    "en-NZ",
    { hour12: false }
  )}`;
}

export function formatTimeThenDate(date?: Date): string {
  if (!date) return "";
  return (
    date.toLocaleTimeString("en-NZ", { hour12: false }) +
    " " +
    date.toLocaleDateString("en-NZ", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );
}
export function formatDateThenTime(date?: Date): string {
  if (!date) return "";
  const timeString = date.toLocaleTimeString("en-NZ", { hour12: false });
  if (dateIsToday(date)) return timeString;
  return (
    date.toLocaleDateString("en-NZ", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " " +
    timeString
  );
}

export function formatDayMonthTime(date?: Date): string {
  if (!date) return "";
  const timeString = date.toLocaleTimeString("en-NZ", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  if (dateIsToday(date)) return timeString;
  return (
    date.toLocaleDateString("en-NZ", {
      month: "long",
      day: "numeric",
    }) +
    " " +
    timeString
  );
}

export function formatHourMinute(date?: Date): string {
  if (!date) return "";
  return date.toLocaleTimeString("en-NZ", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
}

export function elapsedTime(startDate?: Date, endDate?: Date): string {
  if (startDate == undefined || endDate == undefined) return "";
  const difference = endDate.getTime() - startDate.getTime();
  if (difference === 0) return "";

  const hours = Math.floor(difference / 3600000);
  const minutes = Math.floor((difference % 3600000) / 60000);
  const seconds = Math.floor((difference % 60000) / 1000);
  return [
    padTo2Digits(hours),
    padTo2Digits(minutes),
    padTo2Digits(seconds),
  ].join(":");
}

export function formatMonthThenYear(date?: Date) {
  if (!date) return "";
  return date.toLocaleDateString("en-nz", {
    month: "long",
    year: "numeric",
  });
}

export function getDateByMonthsDifferent(
  monthsDifferent: number,
  range: number,
  isEndOfDay: boolean
) {
  const now = new Date();
  const newMonth = new Date(
    now.getFullYear(),
    now.getMonth() + monthsDifferent,
    range
  );
  if (isEndOfDay) {
    newMonth.setHours(23);
    newMonth.setMinutes(59);
    newMonth.setSeconds(59);
  }
  return newMonth;
}

export function addDaysToCurrentDate(days: number) {
  const result = new Date();
  const now = new Date();

  result.setDate(now.getDate() + days);
  return result;
}

export function convertDateStringToTimestamp(
  dateString: string,
  isEndOfDay: boolean
) {
  const convertedTimestamp = new Date(dateString);

  if (isEndOfDay) {
    convertedTimestamp.setHours(23);
    convertedTimestamp.setMinutes(59);
    convertedTimestamp.setSeconds(59);
  } else {
    convertedTimestamp.setHours(0);
    convertedTimestamp.setMinutes(0);
    convertedTimestamp.setSeconds(0);
  }
  return convertedTimestamp;
}

function dateIsToday(date: Date) {
  const today = new Date(Date.now());
  return (
    today.getUTCDate === date.getUTCDate &&
    today.getUTCMonth === date.getUTCMonth &&
    today.getUTCFullYear() === date.getUTCFullYear()
  );
}
