import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FingerprintSpinner = _resolveComponent("FingerprintSpinner")!

  return (_ctx.showLoadingSpinner)
    ? (_openBlock(), _createBlock(_component_FingerprintSpinner, {
        key: 0,
        class: "loading-spinner",
        "animation-duration": 1500,
        size: 64,
        color: "#180F5E"
      }))
    : _createCommentVNode("", true)
}